import { animate } from "./animation.js";
import setPage from "./ext/setPage.js";

export const soon = {
  title: "Come Back Later...",
  body: `<br />
    <h1>来早啦</h1>
    <br /><br /><br />
    <p>
        我知道你很着急，但是你别急，再等一下看啦🧐
    </p>`,
};

export const late =
{
  title: "See you next time...",
  body: `<br />
    <h1>生日过完啦</h1>
    <br /><br /><br />
    <p>
        这是一个奇奇怪怪的小网站，只有生日这天能看到哦！😎
    </p>
`,
};
